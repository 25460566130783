.bg-dark{
    background-color: #282e41;
}
.bg-dark-2{
    background-color: #181d2b;
}

.txt-light,.search-input::placeholder{
    color:#94a2c9!important;
}
.txt-red{
    color:#cc4263!important
}
.bg-red{
    background-color:#cc4263!important
}
.txt-orange{
    color:#ffa616!important
}
.bg-orange{
    background-color:#ffa616!important
}
.txt-green{
    color:#39ef7f!important
}
.bg-green{
    background-color:#39ef7f!important
}
.bg-light{
    background-color: #445076 !important;
}
.bg-light-2{
    background-color:#94a2c9!important;
}
.btn-shaped{
    border-radius: 20px;
}
.bg-dark-3{
    background-color: #0b0f1c!important;
}
.w-340{
    width: 340px;
}
.tab-btn button{
    border-radius: 5px 5px 0px 0px;
    box-shadow: 0px 0px 2px #181d2b;
}
.admin-label{
    right: 40px;
    top: 30px;
    font-size: 1.9em;
}
.bar-style{
    font-size: 1.2em;
    margin: 0 25px 0 10px;
    padding: 0;
    background: none!important;
    border: 0;
    transition: all 0.4s;
}
.bar-style:hover{
    color: #fff!important;
}
.animated{
    transition: all 0.4s;
}
.avatar img{
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid #d9d9d9;
}
.avatar h6{
    align-self: center;
    padding: 0 15px 0 15px;
}

.no-border{
    border:0;
}
.no-border-radius{
    border-radius: 0;
}
.no-padding{
    padding:0;
}
.flex{
    display: flex;
}
.no-margin{
    margin:0
}
.top-nav{
    height: 70px;
    align-items: center;
    flex-direction: row-reverse;
    border-left: 1px solid #000;

}
.dot{
    background-color: #7f63f4;
    width: 13px;
    height: 13px;
    display: inline-block;
    border-radius: 50%;
    border:3px solid #f0f0f0;
    z-index: 10;
    position: absolute;
    top:-4px;
    right:-6px;
}
.notif-icon{
    font-size: 1.2em;
  
    
}
.notif{
    position: relative;
    margin-right: 35px;
}
.left-navbar{
    flex: 1;
    padding-left: 10px;
}
.right-navbar{
    display: flex;
    align-items: center;
    float: 1;
    flex-direction: row-reverse;

}
.search-input:enabled:focus{
    box-shadow: none !important;
    border: 0;
    
}
.search-input{
    border: 0 !important;
    height: 40px;
    border-radius: 0 3px 3px 0 !important;
}
.search-icon{
    display: inline-block;
    height: 40px;
    padding:0.429em;
    border-radius: 3px 0 0 3px;
    padding-left: 15px;
    padding-right:15px ;
    
}
.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    border-bottom:1px solid #000;
    transition: all 0.4s
    
}
.logo img{
    width: 50px;
    height: 50px;
}
.logo h1{
    font-size: 1.1em;
    padding: 0 5px;
    color:#ffffff;
    font-weight: 400;
}
.breadcrumb-top{
    padding: 0 15px;
    height: 40px;
    display: flex;
    align-items: center;
}
.breadcrumb-title{
    color:#fff;
    font-weight: 400;
    font-size: .9em;
}
.breadcrumb-route{
    color:#fff;
    font-weight: 400;
    text-align: right;
    font-size: .75em;
}
.side-nav{
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 70px;
    z-index: 2;
    transition: all 0.4s;
}
.left-side{
    position: fixed;
    top:0;
    bottom: 0;
}
.side-nav ul,.non-list-style{
    list-style:none
}

.side-nav ul a{
    font-size: 0.9em;
    padding: 0 20px;
    line-height: 40px;
    transition: all 0.3s;
    text-decoration: none;
    display: inline-block;
    width: 100%;
}
.side-nav ul li ul li svg{
    margin-left: 15px;
}
.side-nav ul li ul{
    display: none;
}
.side-nav ul li.active{
    color:#fff!important;
    background-color: #282e41;
    border-left:3px solid #7f63f4;
}
.btn-outline-light:hover{
    color: #7f63f4!important;
    background-color: unset;
}
.side-nav ul li:hover{
    background-color: #0003;
}
.navigations{
    text-align: center;
    color:#fff;
    line-height: 40px;
    font-size: .7em;
}
.chart {
    position: relative;
}
.chart label{
    position: absolute;
    right:20px;
    bottom: 10px;
    font-size: .9em;
}
.main-panel{
    padding-top: 30px;
    background-color: #0b0f1c;
    
   
    
}
body{
    background-color: #0b0f1c;
 
}
.coin-icon img{
    width: 40px;
}
.coin-icon{
   float: left;;
}

.coin-datails h6{
    font-size: .9em;
    color:#fff;
}
.coin-datails{
    overflow: hidden;
    padding: 10px;
}
.coin-datails label{
    font-size: .85em;

}
.coin-title{
    float: left;
    padding-left: 7px;
}
.cheange-price{
    font-weight: 600;
    font-size: .8em!important;
    line-height: 23px;
}
.panel-rows{
    padding-top: 20px;
}
.panel-rows h5{
    color:#fff;
    font-size: 1em;
}
.panel-card{
    height: 10vw;;
}
.panel-card h6{
    font-size: .7em;
}
.chart-label label{
    font-size: .6em;
}
.gradient-card h6{
    font-size: .8em;
}
.gradient-card {
    min-height: 100px;
}

.gradient-card img{
    width: 18px;
}
.gradient-card span{
    font-size: .7em;
}
.add-new-wallet{
    height: 100px;
    border: 2px dashed #68778a;
    cursor: pointer;
}
.add-new-wallet label{
    cursor: pointer;
}
.add-new-wallet:hover{
    background-color:#fff1;
    transform: scale(1.02);    
}
.data-table thead th,.data-table td, .data-table th{
    border: none;
    text-align: center;
}
.data-table {
    font-size: .8em;
}
.admin-table{
    font-size: .9em;
}
.data-table tbody{
    box-shadow: 0 0 0 2px #445076;
}
.data-table .badge-warning{
    background-color: #f8951e; 
}
.data-table .badge-success{
    background-color: #35b968;
}
.data-table .badge-danger{
    background-color: #fc3e5e;
}
.main-form label{
    font-size: .7em;
}
.border-light-m{
    border: 1px solid #68778a
}

.footer h6{
    line-height: 60px;
    font-size: .7em;
}
.footer h6 span {
    color:#7f63f4;
}
.open-navigation .side-nav ul a{
        font-size: 1.8em;
        text-align: center;
        line-height: 60px;
}
.open-navigation .bar-style svg{
    transform: rotate(90deg);
}
.open-navigation .side-nav ul li svg{
    margin-right: 0!important;
}
.open-navigation .side-nav ul li span{
    display: none!important;
}
.open-navigation .logo h1{
    display: none!important;
}
.left-navbar .btn-outline-light.focus,.left-navbar .btn-outline-light:focus,.left-navbar .btn-outline-light:hover{
    outline: none!important;
    box-shadow:none!important;
}
.rodal-dialog{
    height: fit-content!important;
    padding: 0!important;
    border-radius: 5px;
}
.btn-wallet{
    width: 100%;
}
.excheange input{
height:54px;
} 
.excheange .dropdown-menu{
    right:0!important;
}
.excheange  .dropdown-toggle::after{
    position: absolute;
    right: 10px;
    top: 25px;
}
.excheange img{
    position: absolute;
    left: 10px;
    top: 15px;
}
button.bg-light:hover,button.bg-light:focus{
    background-color: #fff1!important;
}
.cwa .group-btn button.active{
    background-color: #282e41 ;
}
.cwa .group-btn button{
    line-height: 49px;;
}
.txt-dark{
    color:#707070
}
.cwa p{
    font-size: 0.8em;
    color:#707070
}
.right-cwa button,.seciurty-panel button{
    border-radius: 20px;
}
.purple-border{
    border: 1px solid #445076;
}
.seciurty-panel input{
height: auto !important;
}
.seciurty-panel label{
    font-size: 0.8em;
}
.seciurty-panel h6{
    font-size: 0.9em;
}
.csstransforms .cn-wrapper li:nth-child(odd) a,.csstransforms .cn-wrapper li:nth-child(odd) a{
     background-color: #181d2b!important;
}
.csstransforms .cn-wrapper li:nth-child(even) a{
    background-color:  #445076!important;
}
.cn-button:hover, .cn-button:active, .cn-button:focus{
    color:#445076!important;
}
.react-tel-input .flag-dropdown.open .selected-flag,.react-tel-input .flag-dropdown:hover .selected-flag, .react-tel-input .flag-dropdown:focus .selected-flag{
    background-color: #181d2b!important ;
}
.react-tel-input .selected-flag .arrow.up{
    border-bottom-color: #fff!important;
}
.react-tel-input .selected-flag .arrow{
    border-top-color:#fff!important ;
}
.react-tel-input .flag-dropdown{
    border:1px solid  #181d2b!important ;
}
.file-upload{
    font-size: 3em;
    border:1px solid #707070;
}
.react-datepicker-wrapper{
    line-height: 37px;
}
/*.history>.btn-wallet{
    line-height: 25px;
}*/
.login-form{
    width: 360px;
    transition: all 0.5s;
}
.d-reset{
    height: 230px;
    transform: rotateY(-180deg);
}
.reset{
    transform: rotateY(-180deg);
}
.d-register{
    height: 555px;
    transform: rotateY(180deg);
}
.register{
    transform: rotateY(180deg);
}
.d-login{
    height: 320px;
}
.d-login .login,.d-register .register,.d-login .reset,.d-reset .reset{
    display: block;
}
.d-login .register,.d-login .reset,.d-register .login,.d-register .reset,.d-reset .login,.d-reset .register{
display: none;
}
.forgot-text{
font-size: 0.8em;
}
.forgot-text span{
    color:#1B264D;
    cursor: pointer;
}
.login,.register,.reset{
    position: absolute;
    left:15px;
    right: 15px;
    top:15px;
    bottom:15px;
}
.table-head-bar button,.table-head-bar span{
font-size: 0.8em;
}
.table-head-bar input{
    font-size: 0.6em;
}
.table-head-bar select{
width: 90%;
display: inline-block;
}
.member-main select{
    font-size: 0.7em;
    border-radius: 0;
}
.member-main td:first-child{
    background-color: #445076    ;
}
.t-center{
    justify-content: center;
    height: 50vh;
    align-items: center;
}
.transition, form.adminlogin button, form.adminlogin .question label, form.adminlogin .question input[type="text"] {
    -moz-transition: all 0.25s cubic-bezier(0.53, 0.01, 0.35, 1.5);
    -o-transition: all 0.25s cubic-bezier(0.53, 0.01, 0.35, 1.5);
    -webkit-transition: all 0.25s cubic-bezier(0.53, 0.01, 0.35, 1.5);
    transition: all 0.25s cubic-bezier(0.53, 0.01, 0.35, 1.5);
  }
  

  
 

  form.adminlogin h1 {
    color:#94a2c9;
    font-weight: 100;
    letter-spacing: 0.01em;
    margin-left: 15px;
    margin-bottom: 35px;
    text-transform: uppercase;
  }
  form.adminlogin .l-btn {
    margin-top: 35px;
    line-height: 0;
    font-size: 17px;
    display: inline-block;
    box-sizing: border-box;
    padding: 20px 15px;
    border-radius: 60px;
    font-weight: 100;
    width: 100%;
    letter-spacing: 0.01em;
    position: relative;
  }
.card-fix{
    top:0;
    left:0;
    bottom:0;
    right:0;
}
  form.adminlogin .question {
    position: relative;
    padding: 10px 0;
  }
  form.adminlogin .question:first-of-type {
    padding-top: 0;
  }
  form.adminlogin .question:last-of-type {
    padding-bottom: 0;
  }
  form.adminlogin .question label {
    transform-origin: left center;
    color:#94a2c9;
    font-weight: 100;
    letter-spacing: 0.01em;
    font-size: 17px;
    box-sizing: border-box;
    padding: 10px 15px;
    display: block;
    position: absolute;
    margin-top: -45px;
    pointer-events: none;
  }
  form.adminlogin .question input {
    appearance: none;
    background-color: none;
    border: 1px solid#94a2c9;
    line-height: 0;
    font-size: 17px;
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 10px 15px;
    border-radius: 60px;
    color: #94a2c9;
    font-weight: 100;
    letter-spacing: 0.01em;
    position: relative;
  }
  form.adminlogin .question input:focus {
    outline: none;
    background: #94a2c9;
    color: white;
    margin-top: 10px;
  }
  form.adminlogin {
    position: relative;
    display: inline-block;
    max-width: 700px;
    min-width: 380px;
    box-sizing: border-box;
    padding: 30px 25px;
    margin: 40px 0;
    left: 50%;
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  form.adminlogin .question input:valid {
    margin-top: 10px;
  }
  form.adminlogin .question input:focus ~ label {
    -moz-transform: translate(0, -35px);
    -ms-transform: translate(0, -35px);
    -webkit-transform: translate(0, -35px);
    transform: translate(0, -35px);
  }
  form.adminlogin .question input:valid ~ label {
    text-transform: uppercase;
    font-style: italic;
    -moz-transform: translate(5px, -35px) scale(0.6);
    -ms-transform: translate(5px, -35px) scale(0.6);
    -webkit-transform: translate(5px, -35px) scale(0.6);
    transform: translate(5px, -35px) scale(0.6);
  }
  .react-datepicker-wrapper,.react-datepicker__input-container, .react-datepicker-wrapper input{
      width: 100%;
      height: 38px;
  }
.small-text{
    font-size: 0.7em;
    }
.small-text-1{
    font-size: 0.9em;
}
  .page-link{
      background-color: #445076 ;
      border-color: #94a2c9 ;
      color: #94a2c9;
  }
  .page-item.active .page-link{
    background-color: #94a2c9 ;
    border-color:#445076  ;
    color:#445076;
  }
@media only screen and (max-width: 1344px) {
    .gradient-card h6{
        font-size: .7em;
    }
    .gradient-card span{
        font-size: .6em;
    }
    .panel-rows h5,.logo h1,.side-nav ul li{
        font-size: .9em;
    }
}
@media only screen and (min-width: 1400px) {
    .gradient-card h6{
        font-size: 1em;
    }
    .gradient-card span{
        font-size: .8em;
    }
}
.admin-table td,.admin-table th{
text-align: center;
}
@media only screen and (max-width: 940px) {
    .panel-rows h5,.coin-datails h6,.chart label{
        font-size: .8em;
    }
    .side-nav ul li{
        font-size: .9em!important;
    }
    .side-nav ul li a{
        font-size: 0.9em;
        padding: 0 0px;
        line-height: 30px;
        text-align: center;
    }
    .logo img {
        width: 30px;
        height: 30px;
    }
    .side-nav ul li a svg{
        margin: 0!important;
    }
    .avatar img{
        width: 30px;
        height: 30px;
    }
    .avatar h6{
    font-size: .9em;
    }
    .top-nav{
        height: 50px;
    }
    .notif{
        margin-right: 20px;
    }
    .logo{
        height: 50px;
    }
    .side-nav{
        top:50px
    }
    .breadcrumb-top{
        height: 30px;
    }
    .breadcrumb-title{
        font-size: .75em;
    }

 
}
.rodal-dialog{
    width: 650px!important;
    overflow: auto;
}
@media only screen and (max-width: 992px){
    .side-nav ul li{
        font-size: 1.6em;
    }
}
@media only screen and (max-width: 570px){
    .rodal-dialog{
        width: 350px!important;
    }
    .rodal{
        overflow-y: scroll;
    }
}
.app-version td{
    border: 1px solid #dee2e6!important
}
.react-switch-bg{
    margin-bottom: -4px!important;
}
.cover{position: absolute;
left: 16px;
right: 16px;
top: 0;
bottom: 0;
border-radius: 4px;
background: rgba(0,0,0,0.4);}